import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Header1 from "../components/global/header/Header_1";
import BlockAboutOne from "../components/About/BlockAboutOne";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHome_3";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import BlockHomeDesignOne from "../components/Home/BlockHome_2";
// import ContentServices from "../components/Home/ContentServicesHome";
import ServicesHome from "../components/Services/ServicesHome_3";

function Home() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <Header1 />
        <BlockHomeOne />
        <Directories />
        <BlockHomeDesignOne />
        <CounterUp />
        <BlockAboutOne />
        {/* <ContentServices/> */}
        <ServicesHome />
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[0]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
